@import '../../assets/scss/general';

.tour {
    padding-top: 50px;
    .wrapper {
        @media (min-width: $desktop) {
            width: 900px;
            margin: auto;
        }
    }
}