@import '../../assets/scss/general';

.datalist {
    .wrapper {
        padding: 0 20px 0;
        &.reviews {
          .wrapSelect {
            position: sticky;
            top: 50px;
            padding: 15px 0;
            display: flex;
            justify-content: flex-end;
            background-color: rgba($color-grey-light, 0.95);
            .select {
              width: 100%;
            }
          }
          .cover {
            margin: 20px 0 40px;
            img {
              width: 100%;
              height: auto;
              margin-bottom: 20px;
            }
            .description {
              text-align: left;
              font-size: 0.95rem;
              color: lighten($color-text, 20%);
            }
          }
          .wrapReviews {
            margin-top: 10px;
          }
          &.loading {
            padding-top: 50px;
          }
        }
        @media (min-width: $desktop) {
            padding: 0;
            width: 700px;
            margin: auto;
            &.tour {
              width: 900px;
            }
            &.reviews {
              width: 900px;
              .wrapSelect {
                .select {
                  width: 30%;
                }
              }
              .cover {
                margin: 40px 0 80px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                img {
                  width: 30%;
                  height: auto;
                  align-self: top;
                  margin: 0 25px 0 0;
                }
              }
            }
        }
    }
}