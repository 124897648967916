@import '../../assets/scss/general';

.homepage {
    padding-top: 20px;
    .wrapper {
        @media (min-width: $desktop) {
            padding-top: 50px;
            width: 700px;
            margin: auto;
        }
    }
}