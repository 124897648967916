@import '../../assets/scss/general';

.navbar {
    position: sticky;
    top: 0;
    border-bottom: 1px solid $color-grey;
    background-color: $color-white;
    z-index: 2;
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1;
        padding: 10px;
        .logo {
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        .navigation {
            display: none;
            align-items: center;
            nav {
                display: flex;
                align-items: center;
                .link {
                    border-right: 1px solid $color-grey;
                    font-weight: 500;
                    padding: 0 20px 0 0;
                    margin-right: 20px;
                    text-transform: uppercase;
                    text-decoration: none;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    span {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        position: relative;
                        &:after {
                            content: '';
                            transition: width ease .3s;
                            width: 0;
                            height: 1px;
                            background-color: $color-text;
                            display: block;
                            position: relative;
                            top: 2px;
                        }
                    }
                    &:hover,
                    &.selected {
                        color: $color-text;
                       span {
                           &:after {
                               width: 100%;
                           }
                       }
                    }
                    &:first-child {
                        border-left: 1px solid $color-grey;
                        padding-left: 20px;
                    }
                }
            }
            .social {
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    li {
                        margin-right: 10px;
                        a {
                            color: lighten($color-black, 50);
                            &:hover {
                                color: lighten($color-black, 70);
                            }
                        }
                        
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .mobileNavigation {
          position: relative;
          visibility: hidden;
          transition: all .5s ease-in-out;
          position: fixed;
          width: 80%;
          top: 0;
          right: -80%;
          bottom: 0;
          background-color: $color-grey-light;
          z-index: 3;
          padding: 50px 25px;
          .close {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 15px;
            right: 15px;
          }
          nav {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            > a {
              text-decoration: none;
              text-transform: uppercase;
              margin-bottom: 10px;
              &:last-child {
                margin-bottom: 0;
              }
            }

          }
          .social {
            margin-top: 40px;
            ul {
              list-style: none;
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: space-around;
              li {
                svg {
                  font-size: 1.5rem;
                }
              }
            }
          }
          &.opened {
            visibility: visible;
            right: 0;
          }
        }
        .hamburger {
          width: 30px;
          height: 30px;
        }
        @media (min-width: $desktop) {
            width: 900px;
            margin: auto;
            padding: 0;
            .navigation {
              display: flex;
            }
            .mobileNavigation {
              display: none;
            }
            .hamburger {
              display: none;
            }
        }
    }
}