@import '../../assets/scss/general';

.header {
    width: 100%;
    height: 560px;
    background-size: cover;
    background-position: center;
    display: none;
    @media (min-width: $desktop) {
      display: block;
  }
}