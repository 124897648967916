@import '../../assets/scss/general';

.bio {
    padding-top: 20px;
    .wrapper {
      padding: 0 20px 0;
        .wrapBio {
            text-align: justify;
            padding-bottom: 40px;
        }
    }
    @media (min-width: $desktop) {
      padding-top: 50px;
      .wrapper {
        padding: 0;
        width: 700px;
        margin: auto;
      }
  }
}