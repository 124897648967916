@import '../../assets/scss/general';

.review {
  border-bottom: 1px solid $color-grey;
  padding-bottom: 40px;
  margin-bottom: 40px;
  text-align: left;
  .content {
    h2 {
      display: none;
      font-size: 0.9rem;
      text-transform: uppercase;
      margin: 0 0 5px;
      font-weight: 400;
    }
  }
  > span {
    font-weight: 700;
    font-size: 0.9rem;
    text-transform: uppercase;
  }
  &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
  }
}