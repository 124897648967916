//fonts
//@import url('https://fonts.googleapis.com/css?family=Overpass');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
//@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

//$font-primary: 'Overpass', sans-serif;
$font-primary: 'Roboto Condensed', sans-serif;
//$font-primary: 'Source Sans Pro', sans-serif;

//colors
$color-white: #fff;
$color-black: #000;
$color-grey: #bfbfbf;
$color-grey-light: #f4f4f4;
$color-text: #333;

//breakpoints
$desktop: 900px;

