@import '../../../node_modules/normalize-scss/sass/normalize';
@import '../scss/variables';

* {
    box-sizing: border-box;
}

body {
    font-family: $font-primary;
    font-size: 18px;
    font-weight: 400;
    color: $color-text;
    background-color: $color-grey-light;
}

a {
    transition: color ease .3s;
    color: $color-text;
    &:hover {
        color: lighten($color-text, 40);
    }
}

h2 {
    font-size: 1.5rem;
    margin: 0 0 15px;
    line-height: 1;
}

p {
    margin: 0 0 10px;
}

:global .icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }